import * as React from "react"
import PropTypes from "prop-types"
import { useSiteMetadata } from "../hooks/use-site-metadata"

import Header from "./header"
import Footer from "./footer"

const Layout = ({ children, pageContext }) => {
  const { title } = useSiteMetadata()

  return (
    <div className="flex flex-col items-center max-w-2xl mx-auto flex-1">
      <Header title={title} />
      <main className="grow">{children}</main>
      <Footer week={pageContext?.week} />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
