import React from "react"

const Valencia = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -1 32 32">
    <path
      stroke="#0071bc"
      d="M0 5h8M0 6h8M0 7h8M0 8h8M0 9h8m-8 1h8m-8 1h8m-8 1h8m-8 1h8m-8 1h8m-8 1h8m-8 1h8m-8 1h8m-8 1h8m-8 1h8m-8 1h8m-8 1h8m-8 1h8m-8 1h8m-8 1h8"
    />
    <path
      stroke="#fddd0b"
      d="M8 5h24M8 6h24M8 9h24M8 10h24M8 13h24M8 14h24M8 17h24M8 18h24M8 21h24M8 22h24"
    />
    <path
      stroke="#da1219"
      d="M8 7h24M8 8h24M8 11h24M8 12h24M8 15h24M8 16h24M8 19h24M8 20h24M8 23h24M8 24h24"
    />
  </svg>
)

export default Valencia
