import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Valencia from "./icons/valencia"

const Header = ({ title }) => (
  <header className="text-xl md:text-3xl mb-4 p-2">
    <Link className="hover:text-blue-700 flex items-center" to="/">
      <div className="h-12 w-12 mr-8">
        <Valencia />
      </div>

      {title}
    </Link>
    <h2 className="text-sm text-center">Practica l'examen de la JQCV</h2>
  </header>
)

Header.propTypes = {
  title: PropTypes.string,
}

Header.defaultProps = {
  title: ``,
}

export default Header
