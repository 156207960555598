import React, { useState, createContext } from "react"

const defaultState = {}
const QuestionContext = createContext(defaultState)

const QuestionContextProvider = ({ children }) => {
  const [state, setState] = useState({})
  const [question, setQuestion] = useState({})

  const initialize = (questionIds, published) => {
    if (typeof window === "undefined") {
      return
    }

    const actual = JSON.parse(localStorage.getItem(`responses_${published}`))

    if (actual) {
      if (Object.keys(state).length === 0) {
        setState(actual)
      }
      return
    }

    const r = questionIds.reduce(
      (prev, id) => ({
        ...prev,
        [id]: {
          visited: false,
          success: false,
          failed: null,
        },
      }),
      {}
    )

    localStorage.setItem(`responses_${published}`, JSON.stringify(r))

    setState(r)
  }

  const setVisited = ({ id }, published) => {
    if (typeof window === "undefined") {
      return
    }

    if (!state || !state[id] || Object.keys(state).length === 0) return

    if (state[id].visited) {
      return
    }

    const actual = JSON.parse(localStorage.getItem(`responses_${published}`))
    const r = {
      ...actual,
      [id]: {
        visited: true,
        success: false,
        failed: null,
      },
    }
    localStorage.setItem(`responses_${published}`, JSON.stringify(r))
    setState(r)
  }

  const processNext = (id, published, success) => {
    if (typeof window === "undefined") {
      return
    }

    const res = JSON.parse(localStorage.getItem(`responses_${published}`))
    const response = res[id]
    const responses = {
      ...res,
      [id]: {
        visited: response.visited,
        success,
        failed: response.failed ? true : !success,
      },
    }

    localStorage.setItem(`responses_${published}`, JSON.stringify(responses))
    setState(responses)

    const qlist = Object.keys(responses).sort()

    let r = qlist
      .slice(qlist.indexOf(id) + 1)
      .find(id => !responses[id].success)
    if (!r) {
      r = qlist.find(id => !responses[id].success)
    }

    if (!r) {
      return null
    }

    return r
  }

  return (
    <QuestionContext.Provider
      value={{
        state,
        setState,
        question,
        setQuestion,
        initialize,
        processNext,
        setVisited,
      }}
    >
      {children}
    </QuestionContext.Provider>
  )
}

const useQuestionContext = () => React.useContext(QuestionContext)

export { QuestionContextProvider, useQuestionContext }
