import React from "react"
import { Link } from "gatsby"
import { useQuestionContext } from "../context/question"

const Footer = ({ week }) => {
  const { question } = useQuestionContext()

  return (
    <footer className="flex w-full  justify-between border-t-4 border-indigo-300 text-sm mt-2 pt-2">
      {!week?.actual && (
        <div className="flex grow flex-1 w-full justify-end pt-2">
          {question?.section?.map((s, index) => (
            <span key={index} className="bg-yellow-100 px-2 py-1 ml-2">
              {s}
            </span>
          ))}
        </div>
      )}
      {week?.prev ? (
        <Link
          className="hover:text-blue-800 flex-1 text-left"
          to={`/${week.prev}`}
        >
          Setmana anterior
        </Link>
      ) : null}
      {week?.next ? (
        <Link
          className="hover:text-blue-800 flex-1 text-right"
          to={`/${week.next}`}
        >
          Setmana següent
        </Link>
      ) : null}
    </footer>
  )
}

export default Footer
