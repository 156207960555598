import React from "react"
import Layout from "./src/components/layout"
import { QuestionContextProvider } from "./src/context/question"
import "./src/styles/global.css"

export const wrapPageElement = ({ element, props }) => (
  <QuestionContextProvider>
    <Layout {...props}>{element}</Layout>
  </QuestionContextProvider>
)
